import { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';

// mui
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import StepContent from '@mui/material/StepContent';
import { Button } from '@mui/material';

// data
import { milestonesData } from '../../../resources/milestone-data';
import { displayDate } from '../../../config/setting';
import { color } from '../../../style/color';
import { style } from '../../../style/style';
import { styled } from '@mui/material/styles';

// components
import Questions from './questionnarie/Questions';

// utils
import { RemortgageUtils } from '../../../utils/RemortgageUtils';

// services
import { LocalStorageService } from '../../../services/LocalStorageService';
import {
  getAccessToken,
  WebServiceClient,
} from '../../../services/WebServiceClient';
import MilestoneExtras from './milestones/MilestoneExtras';

const CssStep = styled(Step)({
  '.MuiStepConnector-root': {
    minHeight: '10px',
    '& .MuiStepConnector-line': {
      minHeight: '10px',
    },
  },
});

export default function Remortgage(props) {
  const mil = Array(milestonesData.length).fill(false);
  const matterRef = LocalStorageService.get('matterRef');
  const org = JSON.parse(LocalStorageService.get('organisation'));
  const [openQuestionnarie, setOpenQuestionnarie] = useState(false);
  const [isHovering, setIsHovering] = useState();
  const [indexes, setIndexes] = useState(mil);
  const [disableQuestionnaire, setDisableQuestionnaire] = useState(false);
  const [isQuestionnaireComplete, setIsQuestionnaireComplete] = useState(false);
  const [milestones, setMilestones] = useState(milestonesData);
  const [milestoneExtras, setMilestoneExtras] = useState([]);
  const [caseCollapsed, setCaseCollapsed] = useState(false);

  const isComplete = key => {
    let progress = 0;
    if (
      props.mortgage.Mortgage &&
      props.mortgage.Mortgage.Milestones &&
      props.mortgage.Mortgage.Milestones.milestones
    ) {
      if (
        props.mortgage.Mortgage.Milestones.milestones.find(
          m => m.name === key && !m.milestone,
        )
      ) {
        progress = props.mortgage.Mortgage.Milestones.milestones.find(
          m => m.name === key && !m.milestone,
        ).progression;
      }
    }

    return progress == 2;
  };

  const getMilestoneContent = (key, isComplete) => {
    switch (key) {
      case 'QuestionnaireReceived':
        return (
          <>
            <p style={{ fontSize: '12px', margin: '0' }}>
              {getMilestoneDescription(key, isComplete)}
            </p>
            {!isComplete && !disableQuestionnaire ? (
              <Button
                variant="contained"
                style={{ marginTop: '10px' }}
                sx={style.darkBlueButton}
                onClick={() => setOpenQuestionnarie(true)}
              >
                Complete Questionnaire
              </Button>
            ) : null}
          </>
        );

      default:
        return (
          <p style={{ fontSize: '12px', margin: '0' }}>
            {getMilestoneDescription(key)}
          </p>
        );
    }
  };

  const getMilestoneDescription = key => {
    let content = isComplete(key)
      ? milestones.find(m => m.key === key).complete
      : milestones.find(m => m.key === key).incomplete;

    if (key === 'PreCompletionSetup' && isComplete(key)) {
      let date = props.mortgage.Mortgage.Milestones.milestones.find(
        m => m.name === key,
      ).completedAt;
      content = content.replace('{date}', displayDate(date));
    }

    if (
      !content &&
      key === 'QuestionnaireReceived' &&
      props?.mortgage?.Mortgage?.MatterAdminDetails?.IsLms
    ) {
      content = 'Please complete the questionnaire provided to you by LMS.';
    }

    return content;
  };
  const completeStepIcon = props => {
    return <CheckCircleIcon color="complete" />;
  };
  const incompleteStepIcon = props => {
    return <ErrorIcon color="incomplete" />;
  };
  const extraStepIcon = props => {
    return <ErrorIcon style={{ color: color.errorYellow }} />;
  };

  const handleOpenStep = index => {
    let value = indexes[index];
    setIndexes([
      ...indexes.slice(0, index),
      !value,
      ...indexes.slice(index + 1, indexes.length),
    ]);
  };

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: '/remortgage' });
  }, []);

  useEffect(() => {
    const config = {
      headers: { Authorization: getAccessToken() },
    };
    const params = {
      orgId: org.id,
      siteId: null,
    };
    WebServiceClient.get('/mortgage/borrowers/active', params, config)
      .then(function (response) {
        response.data.forEach(item => {
          if (
            item.Mortgage.RegMatterReference ===
            props.mortgage.Mortgage.RegMatterReference
          ) {
            return setMilestoneExtras([
              ...item.Mortgage?.Milestones?.milestones,
            ]);
          }
        });
        if (response.data) {
          let questionaire = response.data.find(
            q => q.MatterReference === matterRef,
          );

          if (questionaire && questionaire.Mortgage) {
            if (
              (questionaire.Mortgage.MatterAdminDetails &&
                questionaire.Mortgage.MatterAdminDetails.IsLms) ||
              (questionaire.Mortgage.Questionaire &&
                questionaire.Mortgage.Questionaire.QuestionnaireCompleteInP4W)
            ) {
              setDisableQuestionnaire(true);
            }
            if (
              questionaire.Mortgage.Questionaire &&
              questionaire.Mortgage.Questionaire.Completed
            ) {
              setIsQuestionnaireComplete(true);
            }
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [props.mortgage]);

  const getMilestonesOpen = () => {
    for (let i = 0; i < milestones.length; i++) {
      let isCompleted = isComplete(milestones[i].key);
      if (!isCompleted) {
        setIndexes([...mil.slice(0, i), true, ...mil.slice(i + 1, mil.length)]);
        break;
      }
    }
  };

  const checkForCollapsed = () => {
    
    console.log("checkForCollapsed", props)
    if (props?.mortgage?.Mortgage?.Milestones?.milestones?.length) {

      let date = props.mortgage.Mortgage.Milestones.milestones.find(m => m.name === 'CollapsedDate')?.completedAt;
      console.log("checkForCollapsed - date", date)

      // if the date is not 1900-01-01 and the date is before now
      if (date !== '1900-01-01' && new Date(date) < new Date()) {
        setCaseCollapsed(true);
      }
    }
  };

  const checkStatus = () => {
    if (
      (props.mortgage.Mortgage.MatterAdminDetails &&
        props.mortgage.Mortgage.MatterAdminDetails.IsLms) ||
      (props.mortgage.Mortgage.Questionaire &&
        props.mortgage.Mortgage.Questionaire.QuestionnaireCompleteInP4W)
    ) {
      setDisableQuestionnaire(true);
    }

    if (
      (props.mortgage.Mortgage.MatterAdminDetails &&
        !props.mortgage.Mortgage.MatterAdminDetails.IsLms) ||
      (props.mortgage.Mortgage.Questionaire &&
        !props.mortgage.Mortgage.Questionaire.QuestionnaireCompleteInP4W)
    ) {
      setDisableQuestionnaire(false);
    }

    if (
      props.mortgage.Mortgage.Questionaire &&
      props.mortgage.Mortgage.Questionaire.Completed
    ) {
      setIsQuestionnaireComplete(true);
    } else {
      setIsQuestionnaireComplete(false);
    }
  };

  useEffect(() => {
    if (props.mortgage.Mortgage) {
      setIsQuestionnaireComplete(false);
      getMilestones();
      checkStatus();
      checkForCollapsed()
    }
  }, [props.mortgage.Mortgage]);

  const getMilestones = () => {
    return milestones?.map((item, index) => {
      const filteredMilestones = milestoneExtras?.filter((element) => {
        return element?.milestone?.toLocaleLowerCase() === item.actionKey.toLocaleLowerCase()
      });

      const setStatus = () => {
        if (filteredMilestones.filter(x => !x?.completed).length) {
          return extraStepIcon;
        }

        if (!isComplete(item.key)) {
          return incompleteStepIcon;
        }

        return completeStepIcon;
      };

      const setTextColour = () => {
        if (filteredMilestones.filter(x => !x?.completed).length) {
          return color.errorYellow;
        }

        if (!isComplete(item.key)) {
          return color.red;
        }

        return color.brandOlive;
      };
      return (
        <CssStep
          active={indexes[index]}
          key={index}
          onClick={() => handleOpenStep(index)}
        >
          <StepLabel StepIconComponent={setStatus()} style={{ padding: '0px' }}>
            <Typography
              variant="body2"
              style={{
                color: setTextColour(),
                transition: 'all .2s ease-out 100ms',
                fontSize: isHovering === index ? '17px' : '',
              }}
              onMouseEnter={() => setIsHovering(index)}
              onMouseLeave={() => setIsHovering(-1)}
            >
              {item.name}
            </Typography>
          </StepLabel>
          <StepContent style={style.step}>
            <p style={{ fontSize: '12px', margin: '0', whiteSpace: 'pre-line' }}>
              {getMilestoneContent(item.key, isQuestionnaireComplete)}
            </p>
            <MilestoneExtras
              data={filteredMilestones}
              index={index}
              mortgage={props.mortgage}
            />
          </StepContent>
        </CssStep>
      );
    });
  };
  // Patch mortgage/:id/milestone\
  return (
    <Paper style={style.remortgage}>
      <Grid container>
        {openQuestionnarie ? (
          <Grid item xs={12} style={{ padding: '15px' }}>
            <Questions
              mortgage={props.mortgage}
              setDisableQuestionnaire={setDisableQuestionnaire}
              setIsQuestionnaireComplete={setIsQuestionnaireComplete}
            />
          </Grid>
        ) : (
          <>
            <Grid item xs={12}>
              <Stack
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={0}
              >
                {props.mortgage.Mortgage && (
                  <Typography variant="h6">
                    MATTER REFERENCE:{' '}
                    {props.mortgage.Mortgage.RegMatterReference}
                  </Typography>
                )}

                {props.mortgage.Mortgage && (
                  <Stack
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                  >
                    <Typography variant="h6">
                      {
                        props.mortgage.Mortgage.PropertyDetails
                          .RemortgageAddressLine1
                      }
                    </Typography>
                    <Typography variant="h6">
                      {RemortgageUtils.getBorrowers(
                        props.mortgage.Mortgage.Borrowers,
                      )}
                    </Typography>
                  </Stack>
                )}
              </Stack>
            </Grid>
            <Grid item xs={12} style={{ padding: '15px', minHeight: '450px' }}>
              {props.mortgage.Mortgage && !caseCollapsed && (
                <>
                  <Stepper orientation="vertical">{getMilestones()}</Stepper>
                </>
              )}

{props.mortgage.Mortgage && caseCollapsed && <Typography style={{ paddingLeft: 0, marginTop: 0, marginLeft: '-5px', color: '#333' }} variant="h6">There are no milestones to display.</Typography>}
            </Grid>
          </>
        )}
      </Grid>
    </Paper>
  );
}
